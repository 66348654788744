body {
	margin: 0;
	padding: 0;
}

*,
*::before,
*::after,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	display: inline-block;
}

a {
	text-decoration: none;
	color: #4f091d;
}
